import { useSelectedItemStore } from '@/store/selectedItemStore';
import { useInspectionStore } from '@/store/inspectionStore';
import { useNavigate } from 'react-router-dom';
import ListEntry from '../list-entry';

export function SiteInspectionTab() {
  const { inspections } = useInspectionStore();
  const { toggleItem, isItemSelected } = useSelectedItemStore();

  const navigate = useNavigate();

  if (inspections.length === 0) {
    return (
      <div className="w-full text-center mt-6 text-sm text-muted-foreground">
        Your Site Inspections tab is empty.
        <br />
        Create a new inspection by selecting observations and clicking "Create Inspection".
      </div>
    );
  }

  return (
    <ul className="divide-y">
      {inspections.map((inspection) => (
        <li
          key={inspection.id}
          className="py-2 px-4 hover:bg-muted/40 transition-colors cursor-pointer"
          onClick={() => navigate(`/dashboard/inspection/${inspection.id}`)}
        >
          <ListEntry
            title={inspection.title || `Site Inspection #${inspection.id}`}
            description={inspection.description}
            date={new Date(inspection.createdAt)}
            checked={isItemSelected({
              remoteId: inspection.id,
              itemType: 'inspection'
            })}
            onCheckedChange={() => toggleItem({ remoteId: inspection.id, itemType: 'inspection' })}
            hasAudio={inspection.observations.some((obs) => obs.voices.length > 0)}
            hasPhotos={inspection.observations.some((obs) => obs.photos.length > 0)}
          />
        </li>
      ))}
    </ul>
  );
}
