import { createPersistentStore } from '@/lib/utils';
import { Report } from '@/types/report';
import { useSelectedItemStore } from './selectedItemStore';
import { api } from './authStore';
import { toast } from 'sonner';

interface PaginationState {
  total: number;
  page: number;
  limit: number;
  totalPages: number;
}

interface ReportStore {
  isLoading: boolean;
  error: string | null;
  reports: Report[];
  searchQuery: string;
  searchDebounceTimeout: NodeJS.Timeout | null;
  pagination: PaginationState | null;
  generateReport: () => Promise<Report | null>;
  regenerateReport: (reportId: number) => Promise<void>;
  fetchReports: (page: number, search?: string) => Promise<void>;
  updateDescription: (reportId: number, newDescription: string) => Promise<void>;
  updateTitle: (reportId: number, newTitle: string) => Promise<void>;
}

// TODO: fixme use a library for caching loading error state etc...
export const useReportStore = createPersistentStore<ReportStore>('reportData', (set, get) => ({
  reports: [],
  isLoading: false,
  error: null,
  searchQuery: '',
  searchDebounceTimeout: null,
  pagination: null,
  fetchReports: async (page: number = 1, search?: string) => {
    set({ isLoading: true, error: null });
    try {
      const searchQuery = search ?? get().searchQuery;
      const response = await api.get(
        `/protected/report/list?page=${page}&limit=20${searchQuery ? `&search=${encodeURIComponent(searchQuery)}` : ''}`
      );
      set({
        reports: response.data.reports,
        pagination: response.data.pagination,
        isLoading: false
      });
    } catch (error) {
      const errorMessage = error instanceof Error ? error.message : 'An error occurred fetching reports';
      set({
        error: errorMessage,
        isLoading: false
      });
      toast.error(errorMessage);
    }
  },
  generateReport: async () => {
    const selectedItems = useSelectedItemStore.getState().selectedItems;
    if (selectedItems.length === 0) {
      toast.error('No items selected');
      return null;
    }
    try {
      const response = await api.post('/protected/report/generate', { items: selectedItems });
      await get().fetchReports(1);
      return response.data;
    } catch (error) {
      const errorMessage = error instanceof Error ? error.message : 'An error occurred generating report';
      toast.error(errorMessage);
      return null;
    }
  },
  regenerateReport: async (reportId: number) => {
    try {
      const response = await api.post(`/protected/report/${reportId}/regenerate`);
      set((state) => ({
        reports: state.reports.map((report) =>
          report.id === reportId ? { ...report, llm_summary: response.data.llm_summary } : report
        )
      }));
    } catch (error) {
      const errorMessage = error instanceof Error ? error.message : 'An error occurred regenerating report';
      toast.error(errorMessage);
    }
  },
  updateDescription: async (_reportId: number, _newDescription: string) => {
    console.log('updating description');
  },
  updateTitle: async (_reportId: number, _newTitle: string) => {
    console.log('updating title');
  }
}));
