import { useObservationStore } from '@/store/observationStore';
import { RefreshCcw } from 'lucide-react';
import { Button } from '@/components/ui/button';
import { Tabs, TabsList, TabsTrigger } from '@/components/ui/tabs';
import { useAuthStore } from '@/store/authStore.ts';
import { tabsConfig, UserType } from '@/lib/categories';
import { useMediaStore } from '@/store/mediaStore';
import { useSelectedItemStore } from '@/store/selectedItemStore.ts';
import { Skeleton } from '@/components/ui/skeleton.tsx';
import { ExplorerNavBar } from './explorer-navbar';

export function ExplorerList({
  currentTab,
  setCurrentTab
}: {
  currentTab: string;
  setCurrentTab: (tab: string) => void;
}) {
  // fixme: hacky loading states fix this 🤢
  const { isLoading: obsLoading, error, retry } = useObservationStore();
  const { isLoading: mediaLoading } = useMediaStore();
  const { user } = useAuthStore();
  const { clearSelectedItems } = useSelectedItemStore();

  return (
    <>
      <ExplorerNavBar currentTab={currentTab} />
      <Tabs
        value={currentTab}
        onValueChange={(value) => {
          clearSelectedItems();
          setCurrentTab(value);
        }}
      >
        <div className="bg-stone-50 pb-2 border-b">
          <TabsList className="border-b px-0 sm:px-4 bg-stone-50 rounded-none border-none">
            <div className="flex flex-wrap">
              {tabsConfig[user?.user_type || UserType.CONSTRUCTION].map((tab) => (
                <TabsTrigger key={tab.value} value={tab.value}>
                  {tab.label}
                </TabsTrigger>
              ))}
            </div>
          </TabsList>
        </div>
      </Tabs>
      {obsLoading || mediaLoading ? (
        <Skeleton className="m-4 flex h-8" />
      ) : error ? (
        <div className="flex flex-col items-center mt-4 gap-4 w-full">
          <p className="text-destructive">{error}</p>
          <Button variant="outline" size="sm" onClick={() => retry()} className="flex items-center gap-2">
            <RefreshCcw className="h-4 w-4" />
            Retry
          </Button>
        </div>
      ) : (
        <div className="overflow-y-auto">
          {tabsConfig[user?.user_type || UserType.CONSTRUCTION]
            .filter((tab) => tab.value === currentTab)
            .map((tab) => (
              <tab.tabView />
            ))}
        </div>
      )}
    </>
  );
}
