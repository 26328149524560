import { Button } from '@/components/ui/button';
import { Input } from '@/components/ui/input';
import { Label } from '@/components/ui/label';
import { Separator } from '@/components/ui/separator';
import { Sheet, SheetContent, SheetFooter, SheetHeader, SheetTitle } from '@/components/ui/sheet';
import { Tabs, TabsContent, TabsList, TabsTrigger } from '@/components/ui/tabs';
import { Camera, Loader2, Mic, RefreshCw, StopCircle, X } from 'lucide-react';
import { formatTime } from '@/lib/utils';
import { useObservationStore } from '@/store/observationStore';
import { usePhotoStore } from '@/store/photoStore';
import { useRecordingStore } from '@/store/recordingStore';
import { useEffect, useState } from 'react';
import { CurrentMedia, CurrentPhoto, CurrentVoice } from '@/types/observation';
import { useNavigate } from 'react-router-dom';

function CurrentVoiceDisplay({
  recording,
  index,
  removeRecording
}: {
  recording: CurrentVoice;
  index: number;
  removeRecording: (id: string) => void;
}) {
  return (
    <div key={recording.id} className="px-4 py-2 bg-muted rounded-md mb-2">
      <div className="flex items-center justify-between mb-1">
        <h3 className="font-semibold">Recording {index + 1}</h3>
        <div className="flex items-center gap-1">
          {recording.remoteId == null && !recording.failed && <Loader2 className="animate-spin h-4 w-4 mr-2" />}
          {recording.failed && (
            <Button variant="ghost" size="icon">
              <RefreshCw className="h-4 w-4" />
            </Button>
          )}
          <Button variant="destructive" size="icon" onClick={() => removeRecording(recording.id)}>
            <X className="h-4 w-4" />
          </Button>
        </div>
      </div>
      <audio controls className="w-full">
        <source src={recording.audioUrl} type="audio/mp4" />
        Your browser does not support the audio element.
      </audio>
      {recording.transcript == null && recording.remoteId != null ? (
        <p className="italic mb-2 text-muted-foreground">No transcript found</p>
      ) : (
        recording.transcript && (
          <Tabs defaultValue="summary" className="w-full">
            <TabsList>
              <TabsTrigger value="summary">Summary</TabsTrigger>
              <TabsTrigger value="transcript">Transcript</TabsTrigger>
            </TabsList>
            <TabsContent value="transcript">{recording.transcript}</TabsContent>
            <TabsContent value="summary">{recording.processedTranscript}</TabsContent>
          </Tabs>
        )
      )}
    </div>
  );
}

function CurrentPhotoDisplay({ photo, removePhoto }: { photo: CurrentPhoto; removePhoto: (id: string) => void }) {
  return (
    <div className="flex flex-col" key={photo.id}>
      <div className="relative">
        <img
          src={photo.url}
          alt={`Uploaded photo ${photo.id}`}
          className={`w-20 h-20 object-cover rounded ${
            photo.failed || photo.remoteId == null ? 'grayscale animate-pulse' : ''
          }`}
        />
        {photo.remoteId == null && !photo.failed && (
          <Loader2 className="absolute inset-0 top-[22px] left-[22px] animate-spin h-8 w-8" />
        )}
        <Button
          variant="destructive"
          size="icon"
          className="absolute -top-2 -right-2 h-6 w-6"
          onClick={() => removePhoto(photo.id)}
        >
          <X className="h-4 w-4" />
        </Button>
      </div>
      {photo.llm_summary && (
        <>
          <h1 className="font-bold mt-2">Summary:</h1>
          <span>{photo.llm_summary}</span>
        </>
      )}
    </div>
  );
}

export function CreateObservationSheet() {
  const { isCreating, setIsCreating, createObservation } = useObservationStore();
  const [submitting, setSubmitting] = useState(false);

  const { currentPhotos, removePhoto, addPhotos } = usePhotoStore();
  const { currentRecordings, isRecording, recordingTime, setIsRecording, removeRecording } = useRecordingStore();

  const [currentMedia, setCurrentMedia] = useState<CurrentMedia[]>([]);
  useEffect(() => {
    const photos = currentPhotos.map((p) => ({
      ...p,
      mediaType: 'photo'
    })) as CurrentMedia[];
    const voices = currentRecordings.map((v) => ({
      ...v,
      mediaType: 'voice'
    })) as CurrentMedia[];
    setCurrentMedia(
      [...voices, ...photos].sort((a, b) => {
        const dateA = new Date(a.uploaded_at).getTime();
        const dateB = new Date(b.uploaded_at).getTime();
        return dateA - dateB;
      })
    );
  }, [currentPhotos, currentRecordings]);

  const navigate = useNavigate();

  const onCreate = async () => {
    setSubmitting(true);
    const response = await createObservation();
    if (response && response.inspection) {
      navigate(`/dashboard/inspection/${response.inspection.id}`);
    }
    setSubmitting(false);
  };

  return (
    <Sheet open={isCreating} onOpenChange={setIsCreating}>
      <SheetContent className="w-screen sm:w-[540px] flex flex-col">
        <SheetHeader>
          <SheetTitle>New Observation</SheetTitle>
        </SheetHeader>

        <div className="flex flex-col overflow-y-auto flex-1">
          {currentMedia.length > 0 ? (
            <div className="p-4 ">
              {currentMedia.map((media, index) =>
                media.mediaType === 'voice' ? (
                  <CurrentVoiceDisplay recording={media} index={index} removeRecording={removeRecording} />
                ) : (
                  <div className="flex flex-wrap gap-2">
                    <CurrentPhotoDisplay photo={media} removePhoto={removePhoto} />
                  </div>
                )
              )}
            </div>
          ) : (
            <div className="p-4 h-full w-full flex items-center justify-center">No media added yet.</div>
          )}
        </div>

        <SheetFooter>
          <div className="w-full">
            <Separator className="mb-4" />
            <div className="flex gap-2">
              <Button
                variant={'secondary'}
                onClick={() => setIsRecording(!isRecording)}
                disabled={isRecording}
                className="flex-1"
              >
                <Mic className="h-4 w-4 mr-2" />
                {isRecording ? formatTime(recordingTime) : 'Start Recording'}
              </Button>
              {isRecording ? (
                <Button
                  variant={'destructive'}
                  onClick={() => setIsRecording(false)}
                  disabled={!isRecording}
                  className="flex-1"
                >
                  <StopCircle className="h-4 w-4 mr-2" />
                  Stop Recording
                </Button>
              ) : (
                <Label htmlFor="photo-upload" className="cursor-pointer flex-1">
                  <Button variant="secondary" className="w-full" asChild>
                    <span>
                      <Camera className="h-4 w-4 mr-2" />
                      Take Photos
                      <Input
                        id="photo-upload"
                        type="file"
                        accept="image/*"
                        multiple
                        className="hidden"
                        onChange={(e) => addPhotos(e.target.files)}
                      />
                    </span>
                  </Button>
                </Label>
              )}
            </div>
            <Button
              onClick={onCreate}
              className="w-full mt-4"
              disabled={
                submitting ||
                // check if still uploading
                isRecording ||
                currentRecordings.find((x) => x.remoteId == null) != null ||
                currentPhotos.find((x) => x.remoteId == null) != null
              }
            >
              {submitting && <Loader2 className="animate-spin h-4 w-4 mr-2" />}
              Create
            </Button>
          </div>
        </SheetFooter>
      </SheetContent>
    </Sheet>
  );
}
