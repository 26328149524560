import { Checkbox } from '@/components/ui/checkbox';
import { formatDate } from '@/lib/utils';
import { ImageIcon } from 'lucide-react';
import { AudioLines } from 'lucide-react';
import Markdown from 'react-markdown';

interface ListEntryProps {
  title: string;
  description: string | null;
  date: Date;
  checked: boolean;
  onCheckedChange: (checked: boolean) => void;

  hasAudio: boolean;
  hasPhotos: boolean;
}

export default function ListEntry({
  title,
  description,
  date,
  checked,
  onCheckedChange,
  hasAudio,
  hasPhotos
}: ListEntryProps) {
  return (
    <div className="flex items-center space-x-4 p-1">
      <Checkbox checked={checked} onClick={(e) => e.stopPropagation()} onCheckedChange={onCheckedChange} />
      <div className="flex-1 min-w-0 space-y-1 sm:space-y-0">
        <div className="flex flex-col sm:flex-row sm:items-baseline">
          <h3 className="text-sm font-medium text-gray-900 truncate">{title}</h3>
          <p className="text-sm text-gray-500 truncate sm:ml-2 sm:flex-1">
            {description && (
              <div className="flex">
                <p className="hidden sm:inline"> -&nbsp;</p>
                <Markdown className="line-clamp-1">{description}</Markdown>
              </div>
            )}
          </p>
        </div>
      </div>

      <div className="flex items-center gap-2 sm:flex-row-reverse flex-col">
        <time className="text-sm text-muted-foreground whitespace-nowrap">{formatDate(date)}</time>
        <div className="flex items-center gap-1">
          {hasAudio && <AudioLines className="h-3 w-3" />}
          {hasPhotos && <ImageIcon className="h-3 w-3" />}
          {/* {hasItems && <FileIcon className="h-3 w-3" />} */}
        </div>
      </div>
    </div>
  );
}
