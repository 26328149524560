import { ScrollArea } from '@/components/ui/scroll-area';
import { useSelectedItemStore } from '@/store/selectedItemStore';
import { useNavigate } from 'react-router-dom';
import ListEntry from '../list-entry';
import { useReportStore } from '@/store/reportStore';

export function ReportTab() {
  const { reports } = useReportStore();
  if (reports.length === 0) {
    return (
      <div className="w-full text-center mt-6 text-sm text-muted-foreground">
        Your Reports tab is empty.
        <br />
        Create a new report to start organizing your site inspections.
      </div>
    );
  }

  const navigate = useNavigate();
  const { toggleItem, isItemSelected } = useSelectedItemStore();
  return (
    <ScrollArea className="flex-grow">
      <ul className="divide-y">
        {reports.map((report) => (
          <li
            key={report.id}
            className="py-2 px-4 hover:bg-muted/40 transition-colors cursor-pointer"
            onClick={() => navigate(`/dashboard/report/${report.id}`)}
          >
            <ListEntry
              title={report.title || `Report #${report.id}`}
              description={report.description}
              date={new Date(report.createdAt)}
              checked={isItemSelected({
                remoteId: report.id,
                itemType: 'report'
              })}
              onCheckedChange={() => toggleItem({ remoteId: report.id, itemType: 'report' })}
              hasAudio={report.inspections.some((ins) => ins.observations.some((obs) => obs.voices.length > 0))}
              hasPhotos={report.inspections.some((ins) => ins.observations.some((obs) => obs.photos.length > 0))}
            />
          </li>
        ))}
      </ul>
    </ScrollArea>
  );
}
