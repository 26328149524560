import { create } from 'zustand';
import { api } from './authStore';
import { toast } from 'sonner';
import { CurrentVoice } from '@/types/observation';
import { nanoid } from 'nanoid';
interface RecordingState {
  currentRecordings: CurrentVoice[];
  isRecording: boolean;
  recordingTime: number;
  mediaRecorder: MediaRecorder | null;

  startRecording: () => Promise<void>;
  stopRecording: () => void;
  setIsRecording: (isRecording: boolean) => void;
  removeRecording: (recordingId: string) => void;
  uploadRecording: (recordingId: string, blob: Blob) => Promise<void>;
  regenerateTranscriptAndSummary: (remoteRecordingId: number) => void;
}

export const useRecordingStore = create<RecordingState>((set, get) => ({
  currentRecordings: [],
  isRecording: false,
  recordingTime: 0,
  mediaRecorder: null,

  startRecording: async () => {
    try {
      const stream = await navigator.mediaDevices.getUserMedia({
        audio: true
      });
      const mimeType = 'audio/mp4';
      const mediaRecorder = new MediaRecorder(stream, { mimeType });
      let timerInterval: NodeJS.Timeout;

      mediaRecorder.ondataavailable = async (event) => {
        // triggered on end of recording
        if (event.data.size > 0) {
          const blob = new Blob([event.data], { type: mimeType });
          const newRecording = {
            id: nanoid(10),
            audioUrl: URL.createObjectURL(blob),
            transcript: null,
            processedTranscript: null,
            remoteId: null,
            failed: false,
            uploaded_at: new Date()
          } as CurrentVoice;
          set((state) => ({
            currentRecordings: [...state.currentRecordings, newRecording]
          }));
          await get().uploadRecording(newRecording.id, blob);
        }
      };

      mediaRecorder.onstart = () => {
        set({ isRecording: true });
        timerInterval = setInterval(() => {
          set((state) => ({ recordingTime: state.recordingTime + 1 }));
        }, 1000);
      };

      mediaRecorder.onstop = () => {
        set({ isRecording: false, recordingTime: 0 });
        if (timerInterval) clearInterval(timerInterval);
        stream.getTracks().forEach((track) => track.stop());
      };

      set({ mediaRecorder });
      mediaRecorder.start();
    } catch (error) {
      toast('Error starting recording', {
        description: error instanceof Error ? error.toString() : 'Something went wrong'
      });
      console.error('Error starting recording:', error);
    }
  },

  stopRecording: () => {
    const { mediaRecorder } = get();
    if (mediaRecorder && mediaRecorder.state !== 'inactive') {
      mediaRecorder.stop();
    }
  },

  setIsRecording: (isRecording) => {
    if (isRecording) {
      get().startRecording();
    } else {
      get().stopRecording();
    }
  },

  removeRecording: (recordingId) =>
    set((state) => ({
      currentRecordings: state.currentRecordings.filter((r) => r.id !== recordingId)
    })),

  uploadRecording: async (recordingId, blob) => {
    try {
      const formData = new FormData();
      formData.append('audio', blob);
      const response = await api.post(`/protected/voice/upload`, formData, {
        headers: { 'Content-Type': 'multipart/form-data' },
        maxContentLength: 1000000000,
        maxBodyLength: 1000000000
      });

      set((state) => ({
        currentRecordings: state.currentRecordings.map((r) =>
          r.id === recordingId
            ? {
                ...r,
                transcript: response.data.transcript,
                processedTranscript: response.data.processedTranscript,
                remoteId: response.data.voice_id,
                failed: false
              }
            : r
        )
      }));
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
    } catch (error) {
      set((state) => ({
        currentRecordings: state.currentRecordings.map((r) => (r.id === recordingId ? { ...r, failed: true } : r))
      }));
      toast(`Error processing recording`);
    }
  },
  regenerateTranscriptAndSummary: async (remoteRecordingId: number) => {
    try {
      const response = await api.post(`/protected/voice/${remoteRecordingId}/regenerate`);
      // TODO: use a map
      set((state) => ({
        currentRecordings: state.currentRecordings.map((r) =>
          r.remoteId === remoteRecordingId
            ? {
                ...r,
                transcript: response.data.transcript,
                processedTranscript: response.data.processedTranscript,
                failed: false
              }
            : r
        )
      }));
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
    } catch (error) {
      set((state) => ({
        currentRecordings: state.currentRecordings.map((r) =>
          r.remoteId === remoteRecordingId
            ? {
                ...r,
                failed: true
              }
            : r
        )
      }));
      toast(`Error regenerating recording`);
    }
  }
}));
