import { useMediaStore } from '@/store/mediaStore';
import { Media, Photo, Voice } from '@/types/media';
import { useEffect, useState } from 'react';
import { useSelectedItemStore } from '@/store/selectedItemStore.ts';
import { useNavigate } from 'react-router-dom';
import ListEntry from '../list-entry';

export function VoiceEntry({ voice }: { voice: Voice }) {
  const { toggleItem, isItemSelected } = useSelectedItemStore();
  return (
    <ListEntry
      title={'Recording'}
      description={voice.transcript}
      date={new Date(voice.uploaded_at)}
      checked={isItemSelected({
        remoteId: voice.id,
        itemType: 'recording'
      })}
      onCheckedChange={() => toggleItem({ remoteId: voice.id, itemType: 'recording' })}
      hasAudio={true}
      hasPhotos={false}
    />
  );
}

export function PhotoEntry({ photo }: { photo: Photo }) {
  const { toggleItem, isItemSelected } = useSelectedItemStore();
  return (
    <ListEntry
      title={'Photo'}
      description={photo.llm_summary}
      date={new Date(photo.uploaded_at)}
      checked={isItemSelected({
        remoteId: photo.id,
        itemType: 'photo'
      })}
      onCheckedChange={() => toggleItem({ remoteId: photo.id, itemType: 'photo' })}
      hasAudio={false}
      hasPhotos={true}
    />
  );
}

export function MediaTab() {
  const { photos, voices } = useMediaStore();

  const navigate = useNavigate();

  const [allMedia, setAllMedia] = useState<Media[]>([]);
  useEffect(() => {
    const photoMedia = photos.map((p) => ({
      ...p,
      mediaType: 'photo'
    })) as Media[];
    const voiceMedia = voices.map((v) => ({
      ...v,
      mediaType: 'voice'
    })) as Media[];
    const allMedia = [...photoMedia, ...voiceMedia].sort(
      (a, b) => new Date(b.uploaded_at).getTime() - new Date(a.uploaded_at).getTime()
    );
    setAllMedia(allMedia);
  }, [photos, voices]);

  if (allMedia.length === 0) {
    return (
      <div className="w-full text-center mt-6 text-sm text-muted-foreground">
        Your Media tab is empty.
        <br />
        Medias that don’t appear in other tabs will be shown here.
        <br />
        To add or remove tabs click inbox settings.
      </div>
    );
  }

  return (
    <ul className="divide-y">
      {allMedia.map((media) => (
        <li
          key={media.id}
          className="py-2 px-4 hover:bg-muted/40 transition-colors cursor-pointer"
          onClick={() => navigate(`/dashboard/${media.mediaType}/${media.id}`)}
        >
          {media.mediaType === 'photo' ? <PhotoEntry photo={media as Photo} /> : <VoiceEntry voice={media as Voice} />}
        </li>
      ))}
    </ul>
  );
}
