import { useObservationStore } from '@/store/observationStore';
import { ChevronLeftIcon, ChevronRightIcon, ClipboardPlus, RefreshCwIcon } from 'lucide-react';
import { Button } from '@/components/ui/button';
import { useMediaStore } from '@/store/mediaStore';
import { Checkbox } from '../ui/checkbox';
import { useSelectedItemStore } from '@/store/selectedItemStore.ts';
import { Skeleton } from '@/components/ui/skeleton.tsx';
import { cn } from '@/lib/utils.ts';
import { useInspectionStore } from '@/store/inspectionStore.ts';
import { useReportStore } from '@/store/reportStore';
import { useProjectStore } from '@/store/projectStore';
import { useNavigate } from 'react-router-dom';
import { useState } from 'react';
import { Dialog, DialogContent, DialogDescription, DialogHeader, DialogTitle } from '../ui/dialog';

export function ExplorerNavBar({ currentTab }: { currentTab: string }) {
  const { isLoading: obsLoading, pagination, fetchObservations } = useObservationStore();
  const { isLoading: mediaLoading, fetchMedia } = useMediaStore();
  const { fetchProjects } = useProjectStore();
  const { fetchInspections } = useInspectionStore();
  const { fetchReports, generateReport } = useReportStore();
  const { selectedItems, clearSelectedItems } = useSelectedItemStore();

  const navigate = useNavigate();
  const [disabledGenerateReportDialogOpen, setDisabledGenerateReportDialogOpen] = useState(false);

  const handlePageChange = (direction: 'next' | 'prev') => {
    if (!pagination) return;
    const newPage = direction === 'next' ? pagination.page + 1 : pagination.page - 1;
    if (newPage < 1 || newPage > pagination.totalPages) return;
    fetchObservations(newPage);
  };

  const refresh = () => {
    fetchProjects();
    fetchObservations(1);
    fetchMedia();
    fetchInspections();
    fetchReports(1);
  };
  const renderPaginationControls = () => {
    if (!pagination) return null;
    const start = Math.min((pagination.page - 1) * pagination.limit + 1, pagination.total);
    const end = Math.min(pagination.page * pagination.limit, pagination.total);
    return (
      <div className="flex items-center gap-4">
        <div className="text-sm text-muted-foreground">
          {obsLoading || mediaLoading ? <Skeleton className={'w-12 h-6'} /> : `${start}-${end} of ${pagination.total}`}
        </div>
        <div className="flex items-center gap-2">
          <Button
            variant="ghost"
            size="icon"
            className="h-8 w-8"
            onClick={() => handlePageChange('prev')}
            disabled={pagination.page <= 1}
          >
            <ChevronLeftIcon className="h-4 w-4" />
          </Button>
          <Button
            variant="ghost"
            size="icon"
            className="h-8 w-8"
            onClick={() => handlePageChange('next')}
            disabled={pagination.page >= pagination.totalPages}
          >
            <ChevronRightIcon className="h-4 w-4" />
          </Button>
        </div>
      </div>
    );
  };
  return (
    <>
      <div className="bg-stone-100 px-4 pt-4 pb-2">
        <div className="flex flex-col gap-2">
          <div className="flex items-center justify-between">
            <div className="flex items-center gap-1">
              <Checkbox
                className="ml-2 mr-2"
                //  add `indeterminate` checked state. fix on click to full select
                checked={selectedItems.length > 0}
                onCheckedChange={() => clearSelectedItems()}
              />
              <Button
                variant="ghost"
                size="icon"
                className="h-8 w-8"
                onClick={refresh}
                disabled={obsLoading || mediaLoading}
              >
                <RefreshCwIcon className={cn('h-4 w-4', obsLoading || mediaLoading ? 'animate-spin' : '')} />
              </Button>

              <Button
                variant="ghost"
                size="icon"
                className={cn(
                  'h-8 w-8',
                  selectedItems.length === 0 || (currentTab !== 'site-inspection' && currentTab !== 'observations')
                    ? 'opacity-50'
                    : ''
                )}
                onClick={async () => {
                  if (
                    selectedItems.length === 0 ||
                    (currentTab !== 'site-inspection' && currentTab !== 'observations')
                  ) {
                    setDisabledGenerateReportDialogOpen(true);
                    return;
                  }
                  const report = await generateReport();
                  if (report) navigate(`/dashboard/report/${report.id}`);
                }}
              >
                <ClipboardPlus className="h-4 w-4" />
              </Button>
              {selectedItems.length > 0 && (
                <p className={'text-muted-foreground text-sm italic'}>
                  {selectedItems.length}
                  <span className="hidden sm:inline"> selected</span>
                </p>
              )}
            </div>
            {renderPaginationControls()}
          </div>
        </div>
      </div>
      <Dialog open={disabledGenerateReportDialogOpen} onOpenChange={setDisabledGenerateReportDialogOpen}>
        <DialogContent>
          <DialogHeader>
            <DialogTitle>Cannot generate report</DialogTitle>
            <DialogDescription>
              <ul className="list-disc list-inside text-left">
                <li>
                  You must be in <strong>Site Inspection</strong> or <strong>Observations</strong> tab to generate
                  report.
                </li>
                <li>You must select at least one item to generate a report.</li>
              </ul>
            </DialogDescription>
          </DialogHeader>
        </DialogContent>
      </Dialog>
    </>
  );
}
