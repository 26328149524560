import { Outlet } from 'react-router-dom';
import { Menu, Search, SlidersHorizontal } from 'lucide-react';
import { Input } from '@/components/ui/input.tsx';
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger
} from '@/components/ui/dropdown-menu.tsx';
import { Avatar, AvatarFallback, AvatarImage } from '@/components/ui/avatar.tsx';
import { useObservationStore } from '@/store/observationStore.ts';
import { useAuthStore } from '@/store/authStore.ts';
import { SidebarNav } from '@/components/SidebarNav';
import { Button } from '@/components/ui/button';
import { SheetTrigger, SheetContent, Sheet } from '@/components/ui/sheet';
import { Separator } from '@/components/ui/separator';

export default function HeaderLayout() {
  const { searchQuery, setSearchQuery } = useObservationStore();
  const { logout } = useAuthStore();

  return (
    <div className="flex h-screen w-screen flex-col bg-background">
      <header className="flex items-center justify-between border-b p-2 lg:px-4">
        <div className="flex items-center">
          <div className="w-52 items-start hidden lg:flex">
            <img src="/note-zero-logo.png" className="h-5 object-contain " alt={'NoteZero'} />
          </div>
          <Sheet>
            <SheetTrigger asChild>
              <Button variant="ghost" size="icon" className="lg:hidden">
                <Menu className="h-6 w-6" />
                <span className="sr-only">Toggle menu</span>
              </Button>
            </SheetTrigger>
            <SheetContent side="left" className="w-[240px]" closeButton={false}>
              <div className="pr-2 pt-2">
                <img src="/note-zero-logo.png" className="h-5 object-contain " alt={'NoteZero'} />
                <Separator className="mt-4 mb-2" />
                <SidebarNav />
              </div>
            </SheetContent>
          </Sheet>
        </div>
        <div className="flex w-full ml-2 items-center gap-4">
          <div className="relative w-full">
            <Search className="absolute left-2 top-1/2 h-4 w-4 -translate-y-1/2 transform text-muted-foreground" />
            <Input
              type="search"
              placeholder="Search observations"
              className="w-full pl-8 pr-4"
              value={searchQuery}
              onChange={(e) => setSearchQuery(e.target.value)}
            />
          </div>
          <SlidersHorizontal className="h-4 w-4 text-muted-foreground" />
          <DropdownMenu>
            <DropdownMenuTrigger>
              <Avatar className="h-8 w-8">
                <AvatarImage src="/placeholder-user.jpg" alt="User" />
                <AvatarFallback>U</AvatarFallback>
              </Avatar>
            </DropdownMenuTrigger>
            <DropdownMenuContent>
              <DropdownMenuItem className={'text-destructive'} onClick={logout}>
                Logout
              </DropdownMenuItem>
            </DropdownMenuContent>
          </DropdownMenu>
        </div>
      </header>
      <Outlet />
    </div>
  );
}
